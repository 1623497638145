.footer {
    position: relative;
    margin: 0;
    background-color: #24232354;
    padding: 50px;
    border-top: 5px solid black;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: left;
    margin: 10px auto;
    max-width: 1320px
  }
  
  .sub {
    display: flex;
    justify-content: space-evenly;
  }
  
  .sub p {
    margin: 5px;
  }
  
  @media screen and (max-width: 720px) {
    .sub {
      flex-direction: column;
    }
  }
  