
a { 
  color: rgb(221, 221, 221) !important
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  img {
    cursor: pointer
  }

  .emoji-menu {
    width: 215px;
    display: inline-flex !important;
    flex-wrap: wrap
  }

  .emoji-item {
    display: inline-block
  }

  .product-item-container {
    position: relative;
    margin: 5px 0px;
    width:100%;
    font-size:0;
  }
  
  .product-item p {
    position: absolute;
    bottom: 129px;
    padding: 2px;
    left: 0px;
    font-size: 13px !important;
  }
  .product-item h3 {
    position: absolute;
    top: 0px;
    padding: 2px;
    right: 5px;
    font-size: 15px !important;
    color: black !important
  }
  
  .product-item {     
    width: 18%;
    display: inline-block;
    margin: 10px !important
  }
  
  .product-item-header {
    margin-top: 5px;
    padding: 0 5px;
    font-size:17px !important;
    font-weight: bold;
    line-height: 1.2em;
    max-height: 3.2em;
    min-height: 2.4em;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .product-item-description {
    margin-top: 5px;
    padding: 0 5px;
    font-size:15px !important;
    line-height: 1.2em;
    max-height: 4.4em;
    min-height: 2.4em;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }